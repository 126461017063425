import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

const EmotionalStateDrawer = ({ isOpen, onClose, padState, emotionalImpact, width = 'w-80' }) => {
  const { pleasure, arousal, dominance } = padState;
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const [hoverInfo, setHoverInfo] = useState(null);
  const pointsRef = useRef([]);
  const animationRef = useRef(null);
  const previousStateRef = useRef({ pleasure, arousal, dominance });
  const [showUpdate, setShowUpdate] = useState(false);

  // Animation timing
  const ANIMATION_DURATION = 1000; // 1 second
  const startTimeRef = useRef(null);

  // Play state change sound
  const playStateChangeSound = () => {
    if (!isOpen) return;
    
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }

    const ctx = audioContextRef.current;
    const oscillator = ctx.createOscillator();
    const gainNode = ctx.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(ctx.destination);

    oscillator.frequency.setValueAtTime(523.25, ctx.currentTime); // C5 note
    gainNode.gain.setValueAtTime(0, ctx.currentTime);
    gainNode.gain.linearRampToValueAtTime(0.2, ctx.currentTime + 0.05);
    gainNode.gain.linearRampToValueAtTime(0, ctx.currentTime + 0.3);

    oscillator.start();
    oscillator.stop(ctx.currentTime + 0.3);
  };

  // Handle emotional state updates
  useEffect(() => {
    if (emotionalImpact?.changes && Object.keys(emotionalImpact.changes).length > 0) {
      // Store current state as previous
      previousStateRef.current = { ...padState };
      
      // Play sound and show update indicator
      playStateChangeSound();
      setShowUpdate(true);
      setTimeout(() => setShowUpdate(false), 2000);

      // Start animation
      startTimeRef.current = performance.now();
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      animateStateChange();
    }
  }, [emotionalImpact, padState]);

  // Animate state changes
  const animateStateChange = () => {
    if (!startTimeRef.current) return;

    const elapsed = performance.now() - startTimeRef.current;
    const progress = Math.min(elapsed / ANIMATION_DURATION, 1);

    // Ease in-out function
    const easeProgress = progress < 0.5
      ? 2 * progress * progress
      : -1 + (4 - 2 * progress) * progress;

    if (progress < 1) {
      animationRef.current = requestAnimationFrame(animateStateChange);
    } else {
      startTimeRef.current = null;
    }

    drawChart(easeProgress);
  };

  // Draw chart with interpolated values
  const drawChart = (progress = 1) => {
    if (!canvasRef.current) return;
    
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const radius = Math.min(width, height) * 0.35;
    
    // Clear canvas and points
    ctx.clearRect(0, 0, width, height);
    pointsRef.current = [];

    // Draw radar chart background
    const drawRadarBackground = () => {
      const sides = 3; // PAD dimensions
      const angle = (Math.PI * 2) / sides;
      
      // Draw concentric shapes for scale
      const levels = 5; // Number of level circles
      for (let level = 1; level <= levels; level++) {
        const currentRadius = (radius * level) / levels;
        
        ctx.beginPath();
        for (let i = 0; i <= sides; i++) {
          const x = centerX + currentRadius * Math.cos(angle * i - Math.PI / 2);
          const y = centerY + currentRadius * Math.sin(angle * i - Math.PI / 2);
          if (i === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
        }
        ctx.strokeStyle = `rgba(255, 255, 255, ${0.1 + (level / levels) * 0.1})`;
        ctx.stroke();
      }

      // Draw axis lines
      ctx.beginPath();
      for (let i = 0; i < sides; i++) {
        const x = centerX + radius * Math.cos(angle * i - Math.PI / 2);
        const y = centerY + radius * Math.sin(angle * i - Math.PI / 2);
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(x, y);
      }
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.3)';
      ctx.stroke();

      // Draw labels
      ctx.font = '14px Inter';
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      ctx.textAlign = 'center';
      
      // Pleasure (top)
      ctx.fillText('Pleasure', centerX, centerY - radius - 15);
      
      // Arousal (bottom right)
      const arX = centerX + radius * Math.cos(angle - Math.PI / 2);
      const arY = centerY + radius * Math.sin(angle - Math.PI / 2);
      ctx.fillText('Arousal', arX + 15, arY + 15);
      
      // Dominance (bottom left)
      const domX = centerX + radius * Math.cos(2 * angle - Math.PI / 2);
      const domY = centerY + radius * Math.sin(2 * angle - Math.PI / 2);
      ctx.fillText('Dominance', domX - 15, domY + 15);
    };

    // Draw current emotional state with animation
    const drawEmotionalState = () => {
      const sides = 3;
      const angle = (Math.PI * 2) / sides;
      
      // Get previous and current values
      const prev = previousStateRef.current;
      const current = { pleasure, arousal, dominance };
      
      // Interpolate between previous and current values
      const interpolatedValues = [
        { 
          value: prev.pleasure + (current.pleasure - prev.pleasure) * progress,
          label: 'Pleasure'
        },
        { 
          value: prev.arousal + (current.arousal - prev.arousal) * progress,
          label: 'Arousal'
        },
        { 
          value: prev.dominance + (current.dominance - prev.dominance) * progress,
          label: 'Dominance'
        }
      ].map(v => ({ ...v, normalized: (v.value + 1) / 2 }));
      
      // Draw filled area
      ctx.beginPath();
      interpolatedValues.forEach(({ normalized }, i) => {
        const x = centerX + radius * normalized * Math.cos(angle * i - Math.PI / 2);
        const y = centerY + radius * normalized * Math.sin(angle * i - Math.PI / 2);
        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      });
      ctx.closePath();
      
      // Fill with gradient
      const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius);
      gradient.addColorStop(0, 'rgba(147, 51, 234, 0.3)');
      gradient.addColorStop(1, 'rgba(147, 51, 234, 0.1)');
      ctx.fillStyle = gradient;
      ctx.fill();
      
      // Draw border
      ctx.strokeStyle = 'rgb(147, 51, 234)';
      ctx.lineWidth = 2;
      ctx.stroke();
      
      // Draw points
      interpolatedValues.forEach(({ normalized, label, value }, i) => {
        const x = centerX + radius * normalized * Math.cos(angle * i - Math.PI / 2);
        const y = centerY + radius * normalized * Math.sin(angle * i - Math.PI / 2);
        
        ctx.beginPath();
        ctx.arc(x, y, 4, 0, Math.PI * 2);
        ctx.fillStyle = 'rgb(147, 51, 234)';
        ctx.fill();
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 1;
        ctx.stroke();

        // Store point position for hover detection
        pointsRef.current.push({
          x: x * (canvas.clientWidth / canvas.width),
          y: y * (canvas.clientHeight / canvas.height),
          label,
          value
        });
      });
    };

    drawRadarBackground();
    drawEmotionalState();
  };

  // Initial draw and PAD state updates
  useEffect(() => {
    if (!canvasRef.current || !isOpen) return;
    drawChart();
  }, [isOpen, pleasure, arousal, dominance]);

  // Handle mouse movement
  const handleMouseMove = (e) => {
    if (!canvasRef.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Check if mouse is near any point
    const point = pointsRef.current.find(p => {
      const dx = p.x - x;
      const dy = p.y - y;
      return Math.sqrt(dx * dx + dy * dy) < 10;
    });

    if (point) {
      setHoverInfo({
        x: point.x,
        y: point.y,
        label: point.label,
        value: point.value
      });
    } else {
      setHoverInfo(null);
    }
  };

  // Get descriptive text for a PAD value
  const getValueDescription = (value) => {
    if (value > 0.7) return 'Very High';
    if (value > 0.3) return 'High';
    if (value > -0.3) return 'Neutral';
    if (value > -0.7) return 'Low';
    return 'Very Low';
  };

  return (
    <div className={`fixed right-0 top-16 bottom-0 ${width} bg-zinc-800 border-l border-zinc-700 transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'} overflow-y-auto`}>
      <div className="p-8 min-h-full">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-semibold text-zinc-100">Emotional State</h2>
          <button onClick={onClose} className="text-zinc-400 hover:text-zinc-200 transition-colors">
            <FontAwesomeIcon icon={faHeart} size="lg" />
          </button>
        </div>

        {/* Radar Chart Visualization */}
        <div className="relative mb-8">
          <canvas 
            ref={canvasRef}
            width={500}
            height={500}
            className="w-full aspect-square bg-zinc-900/50 rounded-lg"
            onMouseMove={handleMouseMove}
            onMouseLeave={() => setHoverInfo(null)}
          />
          {showUpdate && emotionalImpact && (
            <div className="absolute top-4 right-4 bg-purple-500/90 text-white px-4 py-2 rounded-full text-sm animate-fade-out">
              State Updated
            </div>
          )}
          {hoverInfo && (
            <div 
              className="absolute pointer-events-none bg-zinc-900/95 text-zinc-100 px-4 py-3 rounded-lg shadow-lg text-sm space-y-2 min-w-[160px]"
              style={{ 
                left: `${hoverInfo.x}px`, 
                top: `${hoverInfo.y - 80}px`,
                transform: 'translateX(-50%)'
              }}
            >
              <div className="font-medium text-base">{hoverInfo.label}</div>
              <div className="flex justify-between items-center">
                <span className="text-zinc-400">Value:</span>
                <span className="font-medium">{hoverInfo.value.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-zinc-400">State:</span>
                <span className="font-medium">{getValueDescription(hoverInfo.value)}</span>
              </div>
            </div>
          )}
        </div>

        {/* Current State Summary */}
        <div className="bg-zinc-900/50 rounded-lg p-6 mb-8">
          <h3 className="text-lg font-medium text-zinc-300 mb-4">Current State</h3>
          <div className="grid grid-cols-3 gap-6">
            <div>
              <div className="text-zinc-400 text-sm mb-1">Pleasure</div>
              <div className="text-xl font-medium text-zinc-100">{getValueDescription(pleasure)}</div>
              <div className="text-zinc-500 text-sm">{pleasure.toFixed(2)}</div>
            </div>
            <div>
              <div className="text-zinc-400 text-sm mb-1">Arousal</div>
              <div className="text-xl font-medium text-zinc-100">{getValueDescription(arousal)}</div>
              <div className="text-zinc-500 text-sm">{arousal.toFixed(2)}</div>
            </div>
            <div>
              <div className="text-zinc-400 text-sm mb-1">Dominance</div>
              <div className="text-xl font-medium text-zinc-100">{getValueDescription(dominance)}</div>
              <div className="text-zinc-500 text-sm">{dominance.toFixed(2)}</div>
            </div>
          </div>
        </div>

        {/* Emotional Impact Analysis */}
        {emotionalImpact && emotionalImpact.explanation && (
          <div className="bg-zinc-900/50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-zinc-300 mb-4">Impact Analysis</h3>
            <p className="text-zinc-200 text-base leading-relaxed mb-4">{emotionalImpact.explanation}</p>
            {emotionalImpact.confidence && (
              <div className="flex items-center gap-3">
                <span className="text-sm text-zinc-400">Confidence:</span>
                <div className="flex-1 h-2 bg-zinc-800 rounded-full overflow-hidden">
                  <div 
                    className="h-full bg-purple-500 rounded-full transition-all duration-300"
                    style={{ width: `${emotionalImpact.confidence * 100}%` }}
                  />
                </div>
                <span className="text-sm text-zinc-400">{Math.round(emotionalImpact.confidence * 100)}%</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmotionalStateDrawer; 