import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faVideo, 
  faHistory, 
  faFileAlt, 
  faChartBar,
  faInfoCircle,
  faImage,
  faPrescription,
  faClipboardCheck,
  faHome,
  faChevronLeft,
  faChevronRight,
  faHeart
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';

export default function NavigationDrawer({ onSelect, showMedilist, showEvaluation, med_images, activePage, navigationItems = [] }) {
  const [isOpen, setIsOpen] = useState(false);

  const defaultNavigationItems = [
    { id: 'live', icon: faVideo, label: 'Live Conversation', description: 'View the current conversation' },
    { id: 'history', icon: faHistory, label: 'Transcript', description: 'View conversation history' },
    { id: 'context', icon: faFileAlt, label: 'Reference', description: 'View case information' },
    { id: 'mentor', icon: faChartBar, label: 'Mentor Report', description: 'View performance analysis' },
    { id: 'instructions', icon: faInfoCircle, label: 'Instructions', description: 'View session instructions' },
    { id: 'emotional', icon: faHeart, label: 'Emotional State', description: 'View emotional state analysis' },
    ...(med_images > 0 ? [{ id: 'images', icon: faImage, label: 'Images', description: 'View medical images' }] : []),
    ...(showMedilist ? [{ id: 'medilist', icon: faPrescription, label: 'Medilist', description: 'View medication list' }] : []),
    ...(showEvaluation ? [{ id: 'evaluation', icon: faClipboardCheck, label: 'Evaluation', description: 'View performance evaluation' }] : []),
    { id: 'Dashboard', icon: faHome, label: 'Dashboard', description: 'Return to dashboard' }
  ];

  const allNavigationItems = [...defaultNavigationItems, ...navigationItems];

  const handleSelect = (id) => {
    onSelect(id);
  };

  return (
    <div 
      className={`fixed right-0 top-1/2 -translate-y-1/2 bg-gray-900 transition-all duration-300 z-50 rounded-l-lg
        ${isOpen ? 'w-48' : 'w-12'}`}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-full bg-gray-900 p-2 rounded-l-lg text-white hover:bg-gray-800 transition-colors"
      >
        <FontAwesomeIcon icon={isOpen ? faChevronRight : faChevronLeft} />
      </button>

      <div className="py-4">
        {allNavigationItems.map((item) => (
          <Tooltip
            key={item.id}
            title={isOpen ? '' : `${item.label}: ${item.description}`}
            placement="left"
            arrow
          >
            <div
              onClick={() => handleSelect(item.id)}
              className={`flex items-center px-4 py-3 cursor-pointer transition-all
                ${activePage === item.id ? 'bg-blue-600' : 'hover:bg-gray-800'}
                ${item.id === 'Dashboard' ? 'text-pink-400' : 'text-white'}`}
            >
              <div className={`relative ${activePage === item.id ? 'animate-pulse' : ''}`}>
                <FontAwesomeIcon icon={item.icon} className="w-5 h-5" />
              </div>
              
              <span className={`ml-3 whitespace-nowrap overflow-hidden transition-all duration-300
                ${isOpen ? 'opacity-100 max-w-full' : 'opacity-0 max-w-0'}`}>
                {item.label}
              </span>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}